export const API_URL = "https://ginaexapi.appgrowthcompany.com/api/v1/Admin/";

// export const media_url="https://lifefit.appgrowthcompany.com/api/v1//Upload/"

export const END_POINTS = {
  login: "login",
  mediaUpload: "upload",
  get_user: "profile",
  logout: "logout",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setYourPassword",
  changePassword: "changePassword",

  // ////profile
  updateProfile: "updateProfile",
  getProfile: "getProfile",

  traveler: "allTravel",

  freightCustomer: "allShipment",

  beneficiary: "allBeneficiary",
  //manage-users
  getUsers: "user",
  getUnregisteredUsers: "unregisteredUser",
  changeUserStatus: "changeStatus",
  exportUsers: "userExports",

  //dashboard
  dashboard: "adminDashboard",

  //cms
  cms: "cms",

  //membership level
  membershipLevel: "membershipInfo",

  //report n issue
  reportIssue: "reportedIssues",
  cancelAndRefund: "cancelRefund",
  cancelledRequest: "cancelledRequest",
  removeItems: "removeItems",

  //faq
  faq: "faq",

  adminReport: "adminReport",
  travellerDetailChart: "travellerDetailChart",
  weightChart: "weightChart",
  countryWiseReport: "countryWiseReport",
  //shipment tracks
  //new orders
  newOrders: "newOrders",
  passengerFlight: "passengerFlight",
  //subscription feature
  feature: "feature",
  passenger: "passengerFlight",

  //subscription
  subscription: "subscriptionPlan",

  //questionaire
  survey: "nutritionTracker",

  //analytics
  userGraph: "userGraph",
  totalRevenuGraph: "totalRevenuGraph",
  totalSubscriptionGraph: "totalSubscriptionGraph",

  //subadmin roles
  subAdminRoles: "subAdminRoles",

  //subadmin
  subAdmin: "subAdmin",

  //review
  review: "userReview",

  //exports
  subscriptionExports: "subscriptionExports",
  earningExports: "earningExports",

  //notifications
  notification: "notification",

  //recieved notification
  adminNotification: "adminNotification",

  // feedback
  feedback: "feedback",
};
