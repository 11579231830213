import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import { Tabs, Tab, Box, IconButton, Menu, MenuItem } from "@mui/material";
import NewTravelersList from "../../features/travelers/newTravelersList";
import NewTravelersFilter from "../../features/travelers/newTravelersFilter";
import ApprovedTravelersList from "../../features/travelers/approvedTravelersList";
import RejectedTravelersList from "../../features/travelers/rejectedTravelersList";
import ApprovedTravelersFilter from "../../features/travelers/approvedTravelersFilter";
import RejectedTravelersFilter from "../../features/travelers/rejectedTravelersFilter";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useLazyGetTravelerQuery } from "../../services/travelers";
import { showError } from "../../constants/toasts";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";
import Loader from "../../constants/Loader";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";
import secureLocalStorage from "react-secure-storage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Travelers = () => {
  const user = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [hidden, setHidden] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [type, setType] = useState(1);
  const [getAllData] = useLazyGetTravelerQuery();
  const [travelers, setTravelers] = useState<any>([]);

  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });
  console.log("selectedRow :", selectedRow);

  const gridApi = useGridApiRef();

  const [valueTabs, setValueTabs] = React.useState(0);

  const [totalDataCount, setTotalDataCount] = useState({
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
  });

  const [dateValue, setDateValue] = React.useState<any>([null, null]);
  console.log(
    "dateValue :",
    dateValue?.[0]?.["$d"] && new Date(dateValue?.[0]?.["$d"])?.toISOString(),
    dateValue?.[1]?.["$d"]
  );

  const [arrivalCities, setArrivalCities] = useState<any>([]);
  const [departureCities, setDepartureCities] = useState<any>([]);
  const [selectedArrivalCities, setSelectedArrivalCities] = useState<any>([]);
  const [selectedDepartureCities, setSelectedDepartureCities] = useState<any>(
    []
  );
  console.log("GINA", selectedDepartureCities, selectedArrivalCities);

  const getAllDataList = async () => {
    try {
      setIsLoading(true);
      const response = await getAllData({
        page: 1,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        console.log("TESSTTTTTTTTTT", response?.data);
        setTravelers(response?.data);
        if (type == 2) {
          let departureCities = [];
          for (let key of response?.data) {
            let flag = 0;
            for (let key2 of departureCities) {
              if (
                key2.name?.toLowerCase()?.trim() ==
                  key?.currentAddress?.city?.toLowerCase()?.trim() &&
                key2.country?.toLowerCase()?.trim() ==
                  key?.currentAddress?.district?.toLowerCase()?.trim()
              ) {
                flag = 1;
              }
            }
            if (flag == 0)
              departureCities.push({
                name: key?.currentAddress?.city?.toLowerCase()?.trim(),
                country: key?.currentAddress?.district?.toLowerCase()?.trim(),
                isSelected: false,
              });
          }
          console.log("TESTER MOHIT", departureCities);

          let arrivalCities = [];
          for (let key of response?.data) {
            let flag = 0;
            for (let key2 of arrivalCities) {
              if (
                key2.name?.toLowerCase()?.trim() ==
                  key?.destinationAddress?.[
                    key?.destinationAddress?.length - 1
                  ]?.city
                    ?.toLowerCase()
                    ?.trim() &&
                key2.country?.toLowerCase()?.trim() ==
                  key?.destinationAddress?.[
                    key?.destinationAddress?.length - 1
                  ]?.district
                    ?.toLowerCase()
                    ?.trim()
              ) {
                flag = 1;
              }
            }
            if (flag == 0)
              arrivalCities.push({
                name: key?.destinationAddress?.[
                  key?.destinationAddress?.length - 1
                ]?.city
                  ?.toLowerCase()
                  ?.trim(),
                country: key?.destinationAddress?.[
                  key?.destinationAddress?.length - 1
                ]?.district
                  ?.toLowerCase()
                  ?.trim(),
                isSelected: false,
              });
          }
          console.log(arrivalCities);
          setArrivalCities(arrivalCities);
          setDepartureCities(departureCities);
        }
      }
    } catch (error: any) {
      console.log("ERRROR", error);
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllDataListTotalCount = async (type: number) => {
    try {
      const response = await getAllData({
        page: 0,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        if (type == 1) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type1: response?.data?.count };
          });
        } else if (type == 2) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type2: response?.data?.count };
          });
        } else if (type == 3) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type3: response?.data?.count };
          });
        } else {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type4: response?.data?.count };
          });
        }
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };

  const getFilteredTravelers = (
    searchTerm: string,
    startDate: any,
    endDate: any
  ) => {
    console.log(
      "searchTerm:string,startDate:Date,endDate:Date :",
      searchTerm,
      startDate,
      endDate
    );
    if (startDate != undefined && endDate != undefined) {
      startDate = new Date(
        Date.parse(startDate) + 24 * 60 * 60 * 1000
      ).toISOString();
      endDate = new Date(
        Date.parse(endDate) + 24 * 60 * 60 * 1000
      ).toISOString();
    }
    return travelers?.filter((data: any) => {
      if (type == 2) {
        if (
          selectedArrivalCities.length == 0 &&
          selectedDepartureCities.length == 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            return true;
          } else {
            if (
              data?.travelDetail?.[0]?.departureDate?.slice(0, 10) >=
                startDate?.toString()?.slice(0, 10) &&
              data?.travelDetail?.[
                data?.travelDetail.length - 1
              ]?.departureDate?.slice(0, 10) <=
                endDate?.toString()?.slice(0, 10)
            ) {
              return true;
            }
          }
        } else if (
          selectedArrivalCities.length != 0 &&
          selectedDepartureCities.length == 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            selectedArrivalCities.some((item: any) => {
              console.log(
                "GINA23city",
                data.destinationAddress?.[
                  data.destinationAddress?.length - 1
                ]?.city
                  ?.toLowerCase()
                  ?.trim() == item.name?.toLowerCase()?.trim()
              );
              console.log(
                "GINA23country",
                data.destinationAddress?.[
                  data.destinationAddress?.length - 1
                ]?.district
                  ?.toLowerCase()
                  ?.trim(),
                item.country?.toLowerCase()?.trim()
              );
              return true;
            });

            return selectedArrivalCities.some(
              (item: any) =>
                data.destinationAddress?.[
                  data.destinationAddress?.length - 1
                ]?.city
                  ?.toLowerCase()
                  ?.trim() == item.name?.toLowerCase()?.trim() &&
                data.destinationAddress?.[
                  data.destinationAddress?.length - 1
                ]?.district
                  ?.toLowerCase()
                  ?.trim() == item.country?.toLowerCase()?.trim()
            );
          } else {
            if (
              selectedArrivalCities.some(
                (item: any) =>
                  data.destinationAddress?.[
                    data.destinationAddress?.length - 1
                  ]?.city
                    ?.toLowerCase()
                    ?.trim() == item.name?.toLowerCase()?.trim() &&
                  data.destinationAddress?.[
                    data.destinationAddress?.length - 1
                  ]?.district
                    ?.toLowerCase()
                    ?.trim() == item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                data?.travelDetail?.[0]?.departureDate?.slice(0, 10) >=
                  startDate?.toString()?.slice(0, 10) &&
                data?.travelDetail?.[
                  data?.travelDetail.length - 1
                ]?.departureDate?.slice(0, 10) <=
                  endDate?.toString()?.slice(0, 10)
              ) {
                return true;
              }
            }
          }
        } else if (
          selectedArrivalCities.length == 0 &&
          selectedDepartureCities.length != 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            return selectedDepartureCities.some(
              (item: any) =>
                data.currentAddress?.city?.toLowerCase()?.trim() ==
                  item.name?.toLowerCase()?.trim() &&
                data.currentAddress?.district?.toLowerCase()?.trim() ==
                  item.country?.toLowerCase()?.trim()
            );
          } else {
            if (
              selectedDepartureCities.some(
                (item: any) =>
                  data.currentAddress?.city?.toLowerCase()?.trim() ==
                    item.name?.toLowerCase()?.trim() &&
                  data.currentAddress?.district?.toLowerCase()?.trim() ==
                    item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                data?.travelDetail?.[0]?.departureDate?.slice(0, 10) >=
                  startDate?.toString()?.slice(0, 10) &&
                data?.travelDetail?.[
                  data?.travelDetail.length - 1
                ]?.departureDate?.slice(0, 10) <=
                  endDate?.toString()?.slice(0, 10)
              ) {
                return true;
              }
            }
          }
        } else if (
          selectedArrivalCities.length != 0 &&
          selectedDepartureCities.length != 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            if (
              selectedDepartureCities.some(
                (item: any) =>
                  data.currentAddress?.city?.toLowerCase()?.trim() ==
                    item.name?.toLowerCase()?.trim() &&
                  data.currentAddress?.district?.toLowerCase()?.trim() ==
                    item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                selectedArrivalCities.some(
                  (item: any) =>
                    data.destinationAddress?.[
                      data.destinationAddress?.length - 1
                    ]?.city
                      ?.toLowerCase()
                      ?.trim() == item.name?.toLowerCase()?.trim() &&
                    data.destinationAddress?.[
                      data.destinationAddress?.length - 1
                    ]?.district
                      ?.toLowerCase()
                      ?.trim() == item.country?.toLowerCase()?.trim()
                )
              ) {
                return true;
              }
            }

            return false;
          } else {
            if (
              selectedDepartureCities.some(
                (item: any) =>
                  data.currentAddress?.city?.toLowerCase()?.trim() ==
                    item.name?.toLowerCase()?.trim() &&
                  data.currentAddress?.district?.toLowerCase()?.trim() ==
                    item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                selectedArrivalCities.some(
                  (item: any) =>
                    data.destinationAddress?.[
                      data.destinationAddress?.length - 1
                    ]?.city
                      ?.toLowerCase()
                      ?.trim() == item.name?.toLowerCase()?.trim() &&
                    data.destinationAddress?.[
                      data.destinationAddress?.length - 1
                    ]?.district
                      ?.toLowerCase()
                      ?.trim() == item.country?.toLowerCase()?.trim()
                )
              ) {
                if (
                  data?.travelDetail?.[0]?.departureDate?.slice(0, 10) >=
                    startDate?.toString()?.slice(0, 10) &&
                  data?.travelDetail?.[
                    data?.travelDetail.length - 1
                  ]?.departureDate?.slice(0, 10) <=
                    endDate?.toString()?.slice(0, 10)
                ) {
                  return true;
                }
              }
            }
          }
        }
      } else {
        if (startDate == undefined || endDate == undefined) {
          if (
            data?.userDetails?.[0]?.fullName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            return true;
          }
        }
        if (startDate !== undefined && endDate !== undefined) {
          if (searchTerm == "") {
            if (
              data?.travelDetail?.[0]?.departureDate?.slice(0, 10) >=
                startDate?.toString()?.slice(0, 10) &&
              data?.travelDetail?.[
                data?.travelDetail.length - 1
              ]?.departureDate?.slice(0, 10) <=
                endDate?.toString()?.slice(0, 10)
            ) {
              return true;
            }
          }
        }
      }
    });
    // (dateValue?.[0]?.['$d'] && new Date(dateValue?.[0]?.['$d'])?.toISOString())

    // travelers.filter((data:any)=>data?.userDetails?.[0]?.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
  };

  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<any>({
    name: true,
    passportId: true,
    ticketVehicleReg: true,
    arrivalCityDate: true,
    mobile: true,
    email: true,
    residencyType: true,
    currentCity: true,
    departureCityDate: true,
    travelNo: true,
    action:
      secureLocalStorage.getItem("role") == ADMIN_ROLE_ID ||
      (secureLocalStorage?.getItem("permissions") &&
        secureLocalStorage?.getItem("permissions") !== "undefined" &&
        JSON.parse(
          (secureLocalStorage?.getItem("permissions") as string) || ""
        )?.[0]?.isEdit)
        ? true
        : false,
  });
  const [columnVisibilityModel2, setColumnVisibilityModel2] = useState<any>({
    TR: true,
    name: true,
    availableWeight: true,
    arrivalCityDate: true,
    mobile: true,
    approveDate: true,
    status: true,
    currentCity: true,
    departureCityDate: true,
    wayOfTravel: true,
    viewProfile: true,
    email: true,
    action:
      secureLocalStorage.getItem("role") == ADMIN_ROLE_ID ||
      (secureLocalStorage?.getItem("permissions") &&
        secureLocalStorage?.getItem("permissions") !== "undefined" &&
        JSON.parse(
          (secureLocalStorage?.getItem("permissions") as string) || ""
        )?.[0]?.isEdit)
        ? true
        : false,
  });
  const [columnVisibilityModel3, setColumnVisibilityModel3] = useState<any>({
    name: true,
    residencyType: true,
    ticketVehicleReg: true,
    email: true,
    passportId: true,
    currentCity: true,
    travelNo: true,
    action:
      secureLocalStorage.getItem("role") == ADMIN_ROLE_ID ||
      (secureLocalStorage?.getItem("permissions") &&
        secureLocalStorage?.getItem("permissions") !== "undefined" &&
        JSON.parse(
          (secureLocalStorage?.getItem("permissions") as string) || ""
        )?.[0]?.isEdit)
        ? true
        : false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  // const[searchTerm2,setSearchTerm2]=useState("");
  // const[searchTerm3,setSearchTerm3]=useState("");

  const [departureCity, setDepartureCity] = useState("");
  const [arrivalCity, setArrivalCity] = useState("");

  // const lastColumn = columns.pop();
  // console.log(lastColumn, "lastColumn");

  //   let hidden = []

  //
  console.log("searchTerm :", searchTerm);
  useEffect(() => {
    getAllDataList();
    getAllDataListTotalCount(1);
    getAllDataListTotalCount(2);
    getAllDataListTotalCount(3);
    getAllDataListTotalCount(4);
  }, []);
  useEffect(() => {
    setType(valueTabs + 1);
    setSearchTerm("");
    setTravelers([]);
    setDateValue(["", ""]);
  }, [valueTabs]);
  useEffect(() => {
    getAllDataList();
    setSelectedRow((prevData: any) => {
      return { ...prevData, row: [] };
    });
    setSelectedDepartureCities([]);
    setSelectedArrivalCities([]);
    setDateValue([null, null]);
  }, [type]);
  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>List of Travelers</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Registrations - Travelers
        </p>
      </div>
      <div className="cards">
        <Box className="custom_tabs" sx={{ width: "100%" }}>
          <CustomTabPanel value={valueTabs} index={0}>
            <NewTravelersFilter
              gridApi={gridApi}
              dateValue={dateValue}
              setDateValue={setDateValue}
              selectedRow={selectedRow}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel1}
              setColumnVisibilityModel={setColumnVisibilityModel1}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={1}>
            <ApprovedTravelersFilter
              setSelectedDepartureCities={setSelectedDepartureCities}
              setSelectedArrivalCities={setSelectedArrivalCities}
              setArrivalCities={setArrivalCities}
              setDepartureCities={setDepartureCities}
              arrivalCities={arrivalCities}
              departureCities={departureCities}
              gridApi={gridApi}
              selectedRow={selectedRow}
              searchTerm={searchTerm}
              dateValue={dateValue}
              setDateValue={setDateValue}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel2}
              setColumnVisibilityModel={setColumnVisibilityModel2}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={2}>
            <RejectedTravelersFilter
              gridApi={gridApi}
              selectedRow={selectedRow}
              searchTerm={searchTerm}
              dateValue={dateValue}
              setDateValue={setDateValue}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel3}
              setColumnVisibilityModel={setColumnVisibilityModel3}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={3}>
            <RejectedTravelersFilter
              gridApi={gridApi}
              selectedRow={selectedRow}
              searchTerm={searchTerm}
              dateValue={dateValue}
              setDateValue={setDateValue}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel3}
              setColumnVisibilityModel={setColumnVisibilityModel3}
            />
          </CustomTabPanel>

          <Tabs
            value={valueTabs}
            onChange={handleChangeTabs}
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab
              label={"New Travelers (" + totalDataCount?.type1 + ")"}
              {...a11yProps(0)}
            />
            <Tab
              label={"Approved Travelers (" + totalDataCount?.type2 + ")"}
              {...a11yProps(1)}
            />
            <Tab
              label={"Rejected Travelers (" + totalDataCount?.type3 + ")"}
              {...a11yProps(2)}
            />
            <Tab
              label={"Cancelled Travelers (" + totalDataCount?.type4 + ")"}
              {...a11yProps(2)}
            />
          </Tabs>

          <CustomTabPanel value={valueTabs} index={0}>
            <NewTravelersList
              setTotalDataCount={setTotalDataCount}
              getDataList={getAllDataList}
              gridApi={gridApi}
              setSelectedRow={setSelectedRow}
              columnVisibilityModel={columnVisibilityModel1}
              travelers={getFilteredTravelers(
                searchTerm,
                dateValue?.[0]?.["$d"] &&
                  new Date(dateValue?.[0]?.["$d"])?.toISOString(),
                dateValue?.[1]?.["$d"] &&
                  new Date(dateValue?.[1]?.["$d"])?.toISOString()
              )}
              hidden={hidden}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={1}>
            <ApprovedTravelersList
              getDataList={getAllDataList}
              gridApi={gridApi}
              setSelectedRow={setSelectedRow}
              columnVisibilityModel={columnVisibilityModel2}
              travelers={getFilteredTravelers(
                searchTerm,
                dateValue?.[0]?.["$d"] &&
                  new Date(dateValue?.[0]?.["$d"])?.toISOString(),
                dateValue?.[1]?.["$d"] &&
                  new Date(dateValue?.[1]?.["$d"])?.toISOString()
              )}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={2}>
            <RejectedTravelersList
              rejected={true}
              setTotalDataCount={setTotalDataCount}
              getDataList={getAllDataList}
              gridApi={gridApi}
              setSelectedRow={setSelectedRow}
              columnVisibilityModel={columnVisibilityModel3}
              travelers={getFilteredTravelers(
                searchTerm,
                dateValue?.[0]?.["$d"] &&
                  new Date(dateValue?.[0]?.["$d"])?.toISOString(),
                dateValue?.[1]?.["$d"] &&
                  new Date(dateValue?.[1]?.["$d"])?.toISOString()
              )}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={3}>
            <RejectedTravelersList
              rejected={false}
              setTotalDataCount={setTotalDataCount}
              getDataList={getAllDataList}
              gridApi={gridApi}
              setSelectedRow={setSelectedRow}
              columnVisibilityModel={columnVisibilityModel3}
              travelers={getFilteredTravelers(
                searchTerm,
                dateValue?.[0]?.["$d"] &&
                  new Date(dateValue?.[0]?.["$d"])?.toISOString(),
                dateValue?.[1]?.["$d"] &&
                  new Date(dateValue?.[1]?.["$d"])?.toISOString()
              )}
            />
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
};

export default Travelers;
