import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import { Tabs, Tab, Box, IconButton, Menu, MenuItem } from "@mui/material";
import NewFreightList from "../../features/freightCustomers/newFreightList";
import ApprovedFreightList from "../../features/freightCustomers/approvedFreightList";
import RejectedFreightList from "../../features/freightCustomers/rejectedFreightList";
import NewFreightFilter from "../../features/freightCustomers/newFreightFilter";
import ApprovedFreightFilter from "../../features/freightCustomers/approvedFreightFilter";
import RejectedFreightFilter from "../../features/freightCustomers/rejectedFreightFilter";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { showError } from "../../constants/toasts";
import { useLazyGetFreightCustomerQuery } from "../../services/freightCustomer";
import { ADMIN_ROLE_ID } from "../../constants/role";
import useAuth from "../../hooks/useAuth";
import secureLocalStorage from "react-secure-storage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FreightCustomers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [hidden, setHidden] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [type, setType] = useState(1);
  const [getAllData] = useLazyGetFreightCustomerQuery();
  const [freightCustomer, setFreightCustomer] = useState<any>([]);

  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });
  console.log("selectedRow :", selectedRow);

  const gridApi = useGridApiRef();

  const [valueTabs, setValueTabs] = React.useState(0);

  const [totalDataCount, setTotalDataCount] = useState({
    type1: 0,
    type2: 0,
    type3: 0,
  });

  const [dateValue, setDateValue] = React.useState<any>([null, null]);
  console.log(
    "dateValue :",
    dateValue?.[0]?.["$d"] && new Date(dateValue?.[0]?.["$d"])?.toISOString(),
    dateValue?.[1]?.["$d"]
  );

  const [arrivalCities, setArrivalCities] = useState<any>([]);
  const [departureCities, setDepartureCities] = useState<any>([]);
  const [selectedArrivalCities, setSelectedArrivalCities] = useState<any>([]);
  const [selectedDepartureCities, setSelectedDepartureCities] = useState<any>(
    []
  );
  console.log("GINA", selectedArrivalCities, selectedDepartureCities);
  const user = useAuth();

  const getAllDataList = async () => {
    try {
      setIsLoading(true);
      const response = await getAllData({
        page: 1,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        console.log("TESSTTTTTTTTTT", response?.data);
        setFreightCustomer(response?.data);
        if (type == 2) {
          let departureCities = [];
          for (let key of response?.data) {
            let flag = 0;
            for (let key2 of departureCities) {
              if (
                key2.name?.toLowerCase()?.trim() ==
                  key?.beneficiaryDetail?.customerCurrentCity
                    ?.toLowerCase()
                    ?.trim() &&
                key2.country?.toLowerCase()?.trim() ==
                  key?.beneficiaryDetail?.customerCurrentDistrict
                    ?.toLowerCase()
                    ?.trim()
              ) {
                flag = 1;
              }
            }
            if (flag == 0)
              departureCities.push({
                name: key?.beneficiaryDetail?.customerCurrentCity
                  ?.toLowerCase()
                  ?.trim(),
                country: key?.beneficiaryDetail?.customerCurrentDistrict
                  ?.toLowerCase()
                  ?.trim(),
                isSelected: false,
              });
          }
          console.log("TESTER MOHIT", departureCities);

          let arrivalCities = [];
          for (let key of response?.data) {
            let flag = 0;
            for (let key2 of arrivalCities) {
              if (
                key2.name?.toLowerCase()?.trim() ==
                  key?.beneficiaryDetail?.city?.toLowerCase()?.trim() &&
                key2.country?.toLowerCase()?.trim() ==
                  key?.beneficiaryDetail?.country?.toLowerCase()?.trim()
              ) {
                flag = 1;
              }
            }
            if (flag == 0)
              arrivalCities.push({
                name: key?.beneficiaryDetail?.city?.toLowerCase()?.trim(),
                country: key?.beneficiaryDetail?.country?.toLowerCase()?.trim(),
                isSelected: false,
              });
          }
          console.log(arrivalCities);
          setArrivalCities(arrivalCities);
          setDepartureCities(departureCities);
        }
        setTotalDataCount((prevData: any) => {
          if (type == 1) return { ...prevData, type1: response?.data?.length };
          if (type == 2) return { ...prevData, type2: response?.data?.length };
          if (type == 3) return { ...prevData, type3: response?.data?.length };
          return prevData;
        });
      }
    } catch (error: any) {
      console.log("ERRROR", error);
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllDataListTotalCount = async (type: number) => {
    try {
      const response = await getAllData({
        page: 0,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        if (type == 1) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type1: response?.data?.count };
          });
        } else if (type == 2) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type2: response?.data?.count };
          });
        } else {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type3: response?.data?.count };
          });
        }
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };

  const getFilteredFreightCustomer = (
    searchTerm: string,
    startDate: any,
    endDate: any
  ) => {
    console.log(
      "searchTerm:string,startDate:Date,endDate:Date :",
      searchTerm,
      startDate,
      endDate
    );
    if (startDate != undefined && endDate != undefined) {
      startDate = new Date(
        Date.parse(startDate) + 24 * 60 * 60 * 1000
      ).toISOString();
      endDate = new Date(
        Date.parse(endDate) + 24 * 60 * 60 * 1000
      ).toISOString();
    }
    return freightCustomer?.filter((data: any) => {
      if (type == 2) {
        if (
          selectedArrivalCities.length == 0 &&
          selectedDepartureCities.length == 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            return true;
          } else {
            if (
              data?.beneficiaryDetail?.createdAt?.slice(0, 10) >=
                startDate?.toString()?.slice(0, 10) &&
              data?.beneficiaryDetail?.createdAt?.slice(0, 10) <=
                endDate?.toString()?.slice(0, 10)
            ) {
              return true;
            }
          }
        } else if (
          selectedArrivalCities.length != 0 &&
          selectedDepartureCities.length == 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            // selectedArrivalCities.some((item:any)=>{

            // console.log("GINA23city",(data.beneficiaryDetail?.city?.toLowerCase()?.trim()==item.name?.toLowerCase()?.trim()));
            // console.log("GINA23country",(data.beneficiaryDetail?.country?.toLowerCase()?.trim()),(item.country?.toLowerCase()?.trim()))
            // return true;

            // })

            return selectedArrivalCities.some(
              (item: any) =>
                data.beneficiaryDetail?.city?.toLowerCase()?.trim() ==
                  item.name?.toLowerCase()?.trim() &&
                data.beneficiaryDetail?.country?.toLowerCase()?.trim() ==
                  item.country?.toLowerCase()?.trim()
            );
          } else {
            if (
              selectedArrivalCities.some(
                (item: any) =>
                  data.beneficiaryDetail?.city?.toLowerCase()?.trim() ==
                    item.name?.toLowerCase()?.trim() &&
                  data.beneficiaryDetail?.country?.toLowerCase()?.trim() ==
                    item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                data?.beneficiaryDetail?.createdAt?.slice(0, 10) >=
                  startDate?.toString()?.slice(0, 10) &&
                data?.beneficiaryDetail?.createdAt?.slice(0, 10) <=
                  endDate?.toString()?.slice(0, 10)
              ) {
                return true;
              }
            }
          }
        } else if (
          selectedArrivalCities.length == 0 &&
          selectedDepartureCities.length != 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            return selectedDepartureCities.some(
              (item: any) =>
                data.beneficiaryDetail?.customerCurrentCity
                  ?.toLowerCase()
                  ?.trim() == item.name?.toLowerCase()?.trim() &&
                data.beneficiaryDetail?.customerCurrentDistrict
                  ?.toLowerCase()
                  ?.trim() == item.country?.toLowerCase()?.trim()
            );
          } else {
            if (
              selectedDepartureCities.some(
                (item: any) =>
                  data.beneficiaryDetail?.customerCurrentCity
                    ?.toLowerCase()
                    ?.trim() == item.name?.toLowerCase()?.trim() &&
                  data.beneficiaryDetail?.customerCurrentDistrict
                    ?.toLowerCase()
                    ?.trim() == item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                data?.beneficiaryDetail?.createdAt?.slice(0, 10) >=
                  startDate?.toString()?.slice(0, 10) &&
                data?.beneficiaryDetail?.createdAt?.slice(0, 10) <=
                  endDate?.toString()?.slice(0, 10)
              ) {
                return true;
              }
            }
          }
        } else if (
          selectedArrivalCities.length != 0 &&
          selectedDepartureCities.length != 0
        ) {
          if (startDate == undefined || endDate == undefined) {
            if (
              selectedDepartureCities.some(
                (item: any) =>
                  data.beneficiaryDetail?.customerCurrentCity
                    ?.toLowerCase()
                    ?.trim() == item.name?.toLowerCase()?.trim() &&
                  data.beneficiaryDetail?.customerCurrentDistrict
                    ?.toLowerCase()
                    ?.trim() == item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                selectedArrivalCities.some(
                  (item: any) =>
                    data.beneficiaryDetail?.city?.toLowerCase()?.trim() ==
                      item.name?.toLowerCase()?.trim() &&
                    data.beneficiaryDetail?.country?.toLowerCase()?.trim() ==
                      item.country?.toLowerCase()?.trim()
                )
              ) {
                return true;
              }
            }

            return false;
          } else {
            if (
              selectedDepartureCities.some(
                (item: any) =>
                  data.beneficiaryDetail?.customerCurrentCity
                    ?.toLowerCase()
                    ?.trim() == item.name?.toLowerCase()?.trim() &&
                  data.beneficiaryDetail?.customerCurrentDistrict
                    ?.toLowerCase()
                    ?.trim() == item.country?.toLowerCase()?.trim()
              )
            ) {
              if (
                selectedArrivalCities.some(
                  (item: any) =>
                    data.beneficiaryDetail?.city?.toLowerCase()?.trim() ==
                      item.name?.toLowerCase()?.trim() &&
                    data.beneficiaryDetail?.country?.toLowerCase()?.trim() ==
                      item.country?.toLowerCase()?.trim()
                )
              ) {
                if (
                  data?.beneficiaryDetail?.createdAt?.slice(0, 10) >=
                    startDate?.toString()?.slice(0, 10) &&
                  data?.beneficiaryDetail?.createdAt?.slice(0, 10) <=
                    endDate?.toString()?.slice(0, 10)
                ) {
                  return true;
                }
              }
            }
          }
        }
      } else {
        if (startDate == undefined || endDate == undefined) {
          if (
            data?.userDetails?.[0]?.fullName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            return true;
          }
        }
        if (startDate !== undefined && endDate !== undefined) {
          if (searchTerm == "") {
            if (
              data?.beneficiaryDetail?.createdAt?.slice(0, 10) >=
                startDate?.toString()?.slice(0, 10) &&
              data?.beneficiaryDetail?.createdAt?.slice(0, 10) <=
                endDate?.toString()?.slice(0, 10)
            ) {
              return true;
            }
          }
        }
      }
    });
    // (dateValue?.[0]?.['$d'] && new Date(dateValue?.[0]?.['$d'])?.toISOString())

    // travelers.filter((data:any)=>data?.userDetails?.[0]?.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
  };

  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<any>({
    TR: true,
    name: true,
    passportId: true,
    currentCity: true,
    shipmentPictures: true,
    shipmentId: true,
    category: true,
    email: true,
    residencyType: true,
    mobile: true,
    destinationCity: true,
    luggageType: true,
    customerComment: true,
    action:
      secureLocalStorage.getItem("role") == ADMIN_ROLE_ID ||
      (secureLocalStorage?.getItem("permissions") &&
        secureLocalStorage?.getItem("permissions") !== "undefined" &&
        JSON.parse(
          (secureLocalStorage?.getItem("permissions") as string) || ""
        )?.[0]?.isEdit)
        ? true
        : false,
    // ticketVehicleReg:true,
    // arrivalCityDate:true,
    // departureCityDate:true,
    // travelNo:true,
    // direction:true
  });
  const [columnVisibilityModel2, setColumnVisibilityModel2] = useState<any>({
    TR: true,
    name: true,
    mobile: true,
    currentCity: true,
    shipmentId: true,
    email: true,
    passportId: true,
    shipmentPictures: true,
    category: true,
    residencyType: true,
    destinationCity: true,
    luggageType: true,
    customerComment: true,
    action:
      secureLocalStorage.getItem("role") == ADMIN_ROLE_ID ||
      (secureLocalStorage?.getItem("permissions") &&
        secureLocalStorage?.getItem("permissions") !== "undefined" &&
        JSON.parse(
          (secureLocalStorage?.getItem("permissions") as string) || ""
        )?.[0]?.isEdit)
        ? true
        : false,
  });
  const [columnVisibilityModel3, setColumnVisibilityModel3] = useState<any>({
    TR: true,
    name: true,
    mobile: true,
    currentCity: true,
    email: true,
    passportId: true,
    shipmentPictures: true,
    category: true,
    residencyType: true,
    shipmentId: true,
    destinationCity: true,
    luggageType: true,
    customerComment: true,
    action:
      secureLocalStorage.getItem("role") == ADMIN_ROLE_ID ||
      (secureLocalStorage?.getItem("permissions") &&
        secureLocalStorage?.getItem("permissions") !== "undefined" &&
        JSON.parse(
          (secureLocalStorage?.getItem("permissions") as string) || ""
        )?.[0]?.isEdit)
        ? true
        : false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  // const[searchTerm2,setSearchTerm2]=useState("");
  // const[searchTerm3,setSearchTerm3]=useState("");

  const [departureCity, setDepartureCity] = useState("");
  const [arrivalCity, setArrivalCity] = useState("");

  // const lastColumn = columns.pop();
  // console.log(lastColumn, "lastColumn");

  //   let hidden = []

  //
  console.log("searchTerm :", searchTerm);
  useEffect(() => {
    getAllDataList();
    getAllDataListTotalCount(1);
    getAllDataListTotalCount(2);
    getAllDataListTotalCount(3);
  }, []);
  useEffect(() => {
    setType(valueTabs + 1);
    setSearchTerm("");
    setFreightCustomer([]);
    setDateValue(["", ""]);
  }, [valueTabs]);
  useEffect(() => {
    getAllDataList();
    setSelectedRow((prevData: any) => {
      return { ...prevData, row: [] };
    });
    setSelectedDepartureCities([]);
    setSelectedArrivalCities([]);
    setDateValue([null, null]);
  }, [type]);
  return (
    <>
      <div className="main_title">
        <h1>List of Freight Customers</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Registrations - Freight Customers
        </p>
      </div>
      <div className="cards">
        <Box className="custom_tabs" sx={{ width: "100%" }}>
          <CustomTabPanel value={valueTabs} index={0}>
            <NewFreightFilter
              gridApi={gridApi}
              dateValue={dateValue}
              setDateValue={setDateValue}
              selectedRow={selectedRow}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel1}
              setColumnVisibilityModel={setColumnVisibilityModel1}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={1}>
            <ApprovedFreightFilter
              setSelectedDepartureCities={setSelectedDepartureCities}
              setSelectedArrivalCities={setSelectedArrivalCities}
              setArrivalCities={setArrivalCities}
              setDepartureCities={setDepartureCities}
              arrivalCities={arrivalCities}
              departureCities={departureCities}
              gridApi={gridApi}
              selectedRow={selectedRow}
              searchTerm={searchTerm}
              dateValue={dateValue}
              setDateValue={setDateValue}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel2}
              setColumnVisibilityModel={setColumnVisibilityModel2}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={2}>
            <RejectedFreightFilter
              gridApi={gridApi}
              selectedRow={selectedRow}
              searchTerm={searchTerm}
              dateValue={dateValue}
              setDateValue={setDateValue}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel3}
              setColumnVisibilityModel={setColumnVisibilityModel3}
            />
          </CustomTabPanel>

          <Tabs
            value={valueTabs}
            onChange={handleChangeTabs}
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab
              label={"New Freight Customers (" + totalDataCount?.type1 + ")"}
              {...a11yProps(0)}
            />
            <Tab
              label={
                "Approved Freight Customers (" + totalDataCount?.type2 + ")"
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                "Rejected Freight Customers (" + totalDataCount?.type3 + ")"
              }
              {...a11yProps(2)}
            />
          </Tabs>

          <CustomTabPanel value={valueTabs} index={0}>
            <NewFreightList
              setTotalDataCount={setTotalDataCount}
              getDataList={getAllDataList}
              gridApi={gridApi}
              setSelectedRow={setSelectedRow}
              columnVisibilityModel={columnVisibilityModel1}
              freightCustomer={getFilteredFreightCustomer(
                searchTerm,
                dateValue?.[0]?.["$d"] &&
                  new Date(dateValue?.[0]?.["$d"])?.toISOString(),
                dateValue?.[1]?.["$d"] &&
                  new Date(dateValue?.[1]?.["$d"])?.toISOString()
              )}
              hidden={hidden}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={1}>
            <ApprovedFreightList
              getDataList={getAllDataList}
              gridApi={gridApi}
              setSelectedRow={setSelectedRow}
              columnVisibilityModel={columnVisibilityModel2}
              freightCustomer={getFilteredFreightCustomer(
                searchTerm,
                dateValue?.[0]?.["$d"] &&
                  new Date(dateValue?.[0]?.["$d"])?.toISOString(),
                dateValue?.[1]?.["$d"] &&
                  new Date(dateValue?.[1]?.["$d"])?.toISOString()
              )}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={2}>
            <RejectedFreightList
              setTotalDataCount={setTotalDataCount}
              getDataList={getAllDataList}
              gridApi={gridApi}
              setSelectedRow={setSelectedRow}
              columnVisibilityModel={columnVisibilityModel3}
              freightCustomer={getFilteredFreightCustomer(
                searchTerm,
                dateValue?.[0]?.["$d"] &&
                  new Date(dateValue?.[0]?.["$d"])?.toISOString(),
                dateValue?.[1]?.["$d"] &&
                  new Date(dateValue?.[1]?.["$d"])?.toISOString()
              )}
            />
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
};

export default FreightCustomers;
