/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";
import { Daum } from "../../types/membershipPlan";

const BronzeList = ({ columnVisibilityModel, membershipLevel, setSelectedRow, gridApi, getDataList }: { columnVisibilityModel: any, membershipLevel: Daum[], setSelectedRow: any, gridApi: any, getDataList: any }) => {

    const navigate = useNavigate();

    const value1 = 4.5;
    const value2 = 4.5;
    const value3 = 4;

    const rows: any = [];
    membershipLevel?.forEach((element: Daum) => {
        rows.push({
            id: element?.userId?._id,
            traveler: `${element?.userId?.firstName} ${element?.userId?.lastName}` || "",
            travelerImage: element?.userId?.personVerification?.profileImage,
            travelerContact: element?.userId?.countryCode + " " + element?.userId?.phone,
            travelerEmail: element?.userId?.email,
            averageRating: element?.avgRating,
            contact: `${element?.userId?.email} ${element?.userId?.countryCode + element?.userId?.phone}`,
            loyalPoints: element?.totalWeightDelivered,
            tripRecorded: element?.totalTripsCount,
            orderNo: element?.ordersDeliveredCount,
            reliability: `${element?.loyaltyLevel}(${element?.reliablityLevel})`,
            commitment: element?.commitmentToDeliver / 20,
            quality: element?.qualityOfDeliver / 20
        })
    })
    const user = useAuth();
    const columns: GridColDef<(typeof rows)[number]>[] = [
        {
            flex: 1,
            minWidth: 180,
            field: "traveler",
            headerName: "Traveler",
            editable: false,
            renderCell: (data: any) => {
                return (
                    <div className="user_block">
                        <figure onClick={() =>
                            ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.userId?._id) : null)

                        }>
                            <img src={data?.row?.travelerImage || "/static/images/user-placeholder.jpg"} alt="" />
                        </figure>
                        <p>
                            <b>{data?.row?.traveler || "Brandon King"}</b>
                            <Rating name="read-only" value={data?.row?.averageRating || 0} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                        </p>
                    </div>
                );
            },
        },
        {
            flex: 1,
            minWidth: 140,
            field: "contact",
            headerName: "Contact",
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <p>{row?.travelerEmail || "youth@gmail.com"} <br /> {row?.travelerContact || "+923456789"}</p>
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "loyalPoints",
            headerName: "Loyal Points",
            editable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 120,
            field: "tripRecorded",
            headerName: "Trip Recorded",
            editable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 100,
            field: "orderNo",
            headerName: "No. Orders",
            editable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 100,
            field: "reliability",
            headerName: "Reliability",
            editable: false,
            sortable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 120,
            field: "commitment",
            headerName: "Commitment",
            editable: false,
            sortable: false,
            renderCell: ({ row }) => {
                return (
                    <Rating className="v2" name="read-only" value={row?.commitment || 0} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "quality",
            headerName: "Quality",
            editable: false,
            sortable: false,
            renderCell: ({ row }) => {
                return (
                    <Rating className="v2" name="read-only" value={row?.quality || 0} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                );
            },
        },
    ];

    let hidden = ["fullName"];

    return (
        <>
            <div className="sorting_table">
                <TableComponent
                    columns={columns}
                    rows={rows}
                    columnVisibilityModel={columnVisibilityModel}
                    gridApi={gridApi}
                    setSelectedRow={setSelectedRow}
                />
            </div>
        </>
    );
};

export default BronzeList;
