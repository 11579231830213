import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, IconButton, Button } from "@mui/material";
import AddNotification from "../../Modals/addNotification";
import { showError, showToast } from "../../constants/toasts";
import useAuth from "../../hooks/useAuth";
import { useLazyGetNotificationQuery, useEditNotificationByIdMutation, useDeleteNotificationByIdMutation } from "../../services/manage-notification";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { isValidInput } from "../../utils/validations";
import { handleDelete } from "../../utils/commonFunctions";
import { ADMIN_ROLE_ID } from "../../constants/role";
import Pagination from "../../components/Pagination";

const ManageNotifications = () => {

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const navigate = useNavigate();
  const user = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<any>(0);
  const [page, setPage] = useState(1);
  const [notification, setNotification] = useState<any>([]);
  //   [{
  //     _id: "23232",
  //     fullName: "John Doe", // Optional
  //     phone: "+1 123-456-7890",
  //     image: "https://example.com/profile_image.jpg",
  //     isBlocked: false,
  //     countryCode: "US",
  //     email: "john.doe@example.com",
  //     userName: "johndoe", // Optional
  //     address: "123 Main St, City, State, Zip"
  // }]
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const convertDate = (datetimeStr: string) => {
    const datetimeObj = new Date(datetimeStr);
    const dateStr = datetimeObj.toISOString().split('T')[0];

    return dateStr; // Output: 2024-04-24

  }
  const [getNotification] = useLazyGetNotificationQuery();
  const [updateUserStatus]: any = useEditNotificationByIdMutation();
  const [deleteById] = useDeleteNotificationByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getNotificationList = async () => {
    setIsLoading(true);
    try {
      const response = await getNotification({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setNotification(response?.data?.data || []);
        // console.log(response?.data?.notification);
        setTotalCount(response?.data?.count);
      } else {
        setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };


  const handleStatusChange = async (index: number) => {
    try {
      const body = {
        isBlocked: !notification[index]?.isBlocked
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateUserStatus({ id: `${notification[index]._id}`, body: encryptedData }).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setNotification((prevData: any) => {
        let temp = [...prevData];
        temp[index] = { ...temp[index], isBlocked: !temp[index].isBlocked };
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  }
  useEffect(() => {
    //   console.log("TESTING")
    getNotificationList();
  }, [debouncedSearchTerm, page]);
  console.log(notification);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);


  return (
    <>
      <div className="main_title">
        <h1>Manage Notifications</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Manage Notifications
        </p>
      </div>

      <div className="cards">
        <div className="table_header">
          <div className="left_s">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                  setPage(1);
                }
              }}
              placeholder="Search..."
            />
          </div>
          <div className="right_s">
            {(user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[7]?.isEdit) ? <Button className="btn btn_secondary" onClick={() => setOpenModal(true)}>Add Notification</Button> : null}
          </div>
        </div>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className="v3">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Send To</TableCell>
                <TableCell>Date</TableCell>
                {(user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[7]?.isDelete) ? <TableCell>Action</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {notification.map((data: any, index: number) => {

                return <TableRow>
                  <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                  <TableCell>{data?.title}</TableCell>
                  <TableCell className="max_c">
                    <p>{data?.message}</p>
                  </TableCell>
                  <TableCell>{data?.type == 1 ? "All Users" : (data?.userArray?.length + " User")}</TableCell>
                  <TableCell>{data?.createdAt?.slice(0, 10)}</TableCell>
                  {(user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[7]?.isDelete) ? <TableCell>
                    <Box>
                      <IconButton onClick={() => handleDelete(deleteById, data?._id, getNotificationList)}>
                        <img src="/static/images/trash_icon.svg" alt="Icon" />
                      </IconButton>
                    </Box>
                  </TableCell> : null}
                </TableRow>

              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          module={notification}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>

      <AddNotification open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} getNotificationList={getNotificationList} />
    </>
  );
};

export default ManageNotifications;
