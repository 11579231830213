import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export const exportToPdf = (rows: any, columns: any, heading?: string) => {
  const doc = new jsPDF({
    orientation: "landscape",
  })
  doc.text(heading ? `${heading} : ` : "GinaEx : ", 20, 10)
  autoTable(doc, {
    theme: "grid",
    headStyles: { fillColor: "#4E148C" },
    columns: columns.map((col: any) => ({ ...col, title: col?.headerName, dataKey: col?.field })),
    body: rows
  })
  doc.save('Gina Admin.pdf')
}